import React from "react";

const ArrowIcon = () => (
  <svg width="14px" height="21px" viewBox="0 0 14 21" version="1.1">
    <title>Arrow</title>
    <desc>Created with Sketch.</desc>
    <g
      id="Landing-page"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="HT-Mobile-Landing-page"
        transform="translate(-181.000000, -636.000000)"
        fill="#191919"
        fillRule="nonzero"
      >
        <g id="Group-17" transform="translate(15.000000, 603.000000)">
          <polygon
            id="Line"
            points="171.25 42.7272423 171.25 33 174.75 33 174.75 42.7272423 180 42.7272423 173.197537 54 166 42.7272423"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default ArrowIcon;
