import ArrowIcon from "./ArrowIcon";
import LangMenuIcon from "./LangMenuIcon";
import PlayIcon from "./PlayIcon";
import QuotationIcon from "./QuotationIcon";
import HamburgerIcon from "./HamburgerIcon";
import ConfirmationIcon from "./ConfirmationIcon";
import PlusIcon from "./PlusIcon";
import MinusIcon from "./MinusIcon";
import LogoIcon from "./LogoIcon";

export {
  ArrowIcon,
  LangMenuIcon,
  PlayIcon,
  QuotationIcon,
  HamburgerIcon,
  ConfirmationIcon,
  PlusIcon,
  MinusIcon,
  LogoIcon,
};
