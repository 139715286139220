import React from "react";

const MinusIcon = () => (
  <svg width="17px" height="3px" viewBox="0 0 17 3" version="1.1">
    <title>Minus</title>
    <desc>Created with Sketch.</desc>
    <g id="Mobile" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="HT-IQ-product-page-mobile-Copy"
        transform="translate(-331.000000, -1672.000000)"
        fill="#FFFFFF"
        fillRule="nonzero"
      >
        <path
          d="M346.67623,1672 C347.047816,1672 347.361338,1672.14189 347.616803,1672.42568 C347.872269,1672.70946 348,1673.06757 348,1673.5 C348,1673.93243 347.872269,1674.29054 347.616803,1674.57432 C347.361338,1674.85811 347.047816,1675 346.67623,1675 L332.32377,1675 C331.952184,1675 331.638662,1674.85135 331.383197,1674.55405 C331.127731,1674.25676 331,1673.90541 331,1673.5 C331,1673.06757 331.127731,1672.70946 331.383197,1672.42568 C331.638662,1672.14189 331.952184,1672 332.32377,1672 L346.67623,1672 Z"
          id="+-copy-3"
        />
      </g>
    </g>
  </svg>
);

export default MinusIcon;
