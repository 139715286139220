const logoPNG = require("./logo.png");
const mainPNG = require("./main.png");
const identifyPNG = require("./identify.png");
const fineTunePNG = require("./fine_tune.png");
const completeEnPNG = require("./completeEN.png");
const completeNlPNG = require("./completeNL.png");
const cardsPNG = require("./cards.png");
const onePNG = require("./one.png");
const twoPNG = require("./two.png");
const threePNG = require("./three.png");
const cambridgePNG = require("./cambridge.png");

export {
  logoPNG,
  mainPNG,
  identifyPNG,
  fineTunePNG,
  completeEnPNG,
  completeNlPNG,
  cambridgePNG,
  cardsPNG,
  onePNG,
  twoPNG,
  threePNG,
};
