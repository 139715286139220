import React from "react";

const LangMenuIcon = () => (
  <svg width="12px" height="7px" viewBox="0 0 12 7" version="1.1">
    <title>Path</title>
    <desc>Created with Sketch.</desc>
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="Menu/Create"
        transform="translate(-1141.000000, -38.000000)"
        stroke="#3D3D3D"
        strokeWidth="2"
      >
        <g id="Group" transform="translate(1113.000000, 29.000000)">
          <polyline id="Path" points="29 10 34 15 39 10" />
        </g>
      </g>
    </g>
  </svg>
);

export default LangMenuIcon;
