import React, { Component } from "react";

import LocalizedLink from "../LocalizedLink";
import Navbar from "./Navbar";
import HamburgerMenu from "./HamburgerMenu";

import { homePath } from "../../utils/paths";

import { logoPNG } from "../../assets/images";

import { IPageContext, IPrismicHeader } from "../../scenes/prismic.model";

import * as styles from "./Header.module.scss";
import { LogoIcon } from "../../assets/icons";

interface IProps {
  data: IPrismicHeader;
  pageContext: IPageContext;
}

export default class Header extends Component<IProps> {
  state = {
    isHamburgerMenuOpen: false,
  };
  render() {
    return (
      <div className={styles.container} onTouchMove={this.closeHamburgerMenu}>
        <div className={styles.logoContainer}>
          <LocalizedLink to={homePath}>
            <LogoIcon />
          </LocalizedLink>
        </div>
        <Navbar {...this.props} />
        <HamburgerMenu
          isOpen={this.state.isHamburgerMenuOpen}
          toggleMenu={this.toggleHamburgerMenu}
          {...this.props}
        />
      </div>
    );
  }

  private closeHamburgerMenu = () => {
    if (this.state.isHamburgerMenuOpen) {
      this.setState({
        isHamburgerMenuOpen: false,
      });
    }
  };

  private toggleHamburgerMenu = () => {
    this.setState({
      isHamburgerMenuOpen: !this.state.isHamburgerMenuOpen,
    });
  };
}
