import React, { Component } from "react";
import { Link } from "gatsby";

import LocalizedLink from "../LocalizedLink";
import {
  homePath,
  sciencePath,
  pricingPath,
  contactUsPath,
  aboutUsPath,
  bookDemoPath,
} from "../../utils/paths";
import { IPrismicHeader } from "../../scenes/prismic.model";
import { HamburgerIcon } from "../../assets/icons";

import * as styles from "./Header.module.scss";

interface IProps {
  isOpen: boolean;
  toggleMenu: () => void;
  data: IPrismicHeader;
  pageContext: {
    locale: string;
  };
}

interface IState {
  isLanguageMenuOpen: boolean;
}

export default class HamburgerMenu extends Component<IProps, IState> {
  state = {
    isLanguageMenuOpen: false,
  };

  render() {
    const { data, pageContext } = this.props;
    return (
      <div className={styles.hamburgerMenuContainer}>
        <LocalizedLink className={styles.bookDemoButton} to={bookDemoPath}>
          {data.book_demo_link.text}
        </LocalizedLink>
        <div onClick={this.props.toggleMenu}>
          <HamburgerIcon />
        </div>
        {this.props.isOpen && (
          <>
            <div className={styles.menuContainer}>
              <ul>
                <li>
                  <LocalizedLink to={sciencePath}>
                    <div className={styles.withBorder}>
                      {data.how_it_works_link.text.toUpperCase()}
                    </div>
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to={pricingPath}>
                    <div className={styles.withBorder}>
                      {data.pricing_link.text.toUpperCase()}
                    </div>
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to={aboutUsPath}>
                    <div className={styles.withBorder}>
                      {data.about_us_link.text.toUpperCase()}
                    </div>
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to={contactUsPath}>
                    <div className={styles.withBorder}>
                      {data.contact_us_link.text.toUpperCase()}
                    </div>
                  </LocalizedLink>
                </li>
                <li>
                  <div
                    className={styles.selectedLanguage}
                    onClick={this.toggleLanguageMenu}
                  >
                    <div
                      className={
                        !this.state.isLanguageMenuOpen ? styles.language : null
                      }
                    >
                      {pageContext.locale === "en-us" ? "ENGLISH" : "DUTCH"}
                    </div>
                  </div>
                  {this.state.isLanguageMenuOpen && (
                    <div
                      className={styles.languageOptions}
                      onClick={this.toggleLanguageMenu}
                    >
                      <Link hrefLang="en-us" to={homePath}>
                        <div>
                          <p>English</p>
                        </div>
                      </Link>
                      <Link hrefLang="nl-nl" to="/nl">
                        <div className={styles.languageOption}>
                          <p>Dutch</p>
                        </div>
                      </Link>
                    </div>
                  )}
                </li>
              </ul>
              <a
                className={styles.loginLink}
                href={`${process.env.HT_APP_URL}/login?locale=${this.props.pageContext.locale}`}
              >
                {data.login_link.text.toUpperCase()}
              </a>
              <a
                className={styles.signupLink}
                href={`${process.env.HT_APP_URL}/register?locale=${this.props.pageContext.locale}`}
              >
                {data.signup_link.text.toUpperCase()}
              </a>
              <Link to={pricingPath} className={styles.getStartedLink}>
                <div>{data.get_started_link.text.toUpperCase()}</div>
              </Link>
            </div>
            <div
              className={styles.hamburgerMenuBackground}
              onClick={this.props.toggleMenu}
            />
          </>
        )}
      </div>
    );
  }

  private toggleLanguageMenu = () => {
    this.setState({
      isLanguageMenuOpen: !this.state.isLanguageMenuOpen,
    });
  };
}
