import React from "react";

const PlayIcon = () => (
  <svg width="42px" height="42px" viewBox="0 0 42 42" version="1.1">
    <title>Play Video</title>
    <desc>Created with Sketch.</desc>
    <g
      id="Landing-page"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="HT-Mobile-Landing-page"
        transform="translate(-264.000000, -517.000000)"
      >
        <g id="Group-22" transform="translate(16.000000, 406.000000)">
          <g id="Group-7" transform="translate(58.000000, 112.000000)">
            <g id="play" transform="translate(191.000000, 0.000000)">
              <circle
                id="Combined-Shape"
                stroke="#81A169"
                strokeWidth="2"
                fill="#FFFFFF"
                cx="20"
                cy="20"
                r="20"
              />
              <polygon
                id="Triangle"
                fill="#81A169"
                transform="translate(21.000000, 20.111111) rotate(90.000000) translate(-21.000000, -20.111111) "
                points="21 15.111111 27.1111111 25.111111 14.8888889 25.111111"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default PlayIcon;
