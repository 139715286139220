import React from "react";

import { cardsPNG } from "../../assets/images";
import { sciencePath, contactUsPath } from "../../utils/paths";
import LocalizedLink from "../LocalizedLink";

import { IPrismicFooter } from "../../scenes/prismic.model";

import * as styles from "./Footer.module.scss";

interface IProps {
  data: IPrismicFooter;
}

export default function Footer(props: IProps) {
  let pathname = "";
  const date = new Date();

  if (typeof window !== `undefined`) {
    pathname = window.location.pathname;
  }

  return (
    <div className={styles.container}>
      <div className={styles.footerTop}>
        <div className={styles.footerContent}>
          <p className={styles.footerContentHeading}>
            {props.data.footer_heading.text}
          </p>
          <p className={styles.footerContentLabel}>
            {props.data.footer_label.text}
          </p>
          {pathname.indexOf("science") === -1 && (
            <LocalizedLink to={sciencePath}>
              <div className={styles.footerContentButton}>
                {props.data.get_started_button.text}
              </div>
            </LocalizedLink>
          )}
          <div className={styles.footerCapterraLink}>
            <a
              href="https://www.capterra.com/reviews/216455/MatchHR?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge"
              target="_blank"
            >
              <img src="https://assets.capterra.com/badge/8c9e347abc9a896c9cab211a50250d4f.png?v=2156225&p=216455" />
            </a>
          </div>
        </div>
      </div>
      <div className={styles.footerBottomContainer}>
        <div className={styles.cardsContainer}>
          <img src={cardsPNG} alt="cards" />
        </div>
        <div className={styles.footerBottom}>
          <div className={styles.copyrightLabel}>
            <p>© {date.getFullYear()} MatchHR</p>
          </div>
          <div className={styles.cardsContainer}>
            <img src={cardsPNG} alt="cards" />
          </div>
          <div className={styles.contactUsLabel}>
            <LocalizedLink to={contactUsPath}>
              <div className={styles.footerBottomLink}>
                {props.data.contact_us_link.text}
              </div>
            </LocalizedLink>
          </div>
          <div className={styles.privacyPolicyLabel}>
            <a
              href={`${process.env.BASE_STORAGE_URL}/privacy-policy/privacy-policy-matchhr.pdf`}
              target="blank"
              className={styles.footerBottomLink}
            >
              {props.data.privacy_policy_link.text}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
