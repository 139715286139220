import React from "react";

const HamburgerIcon = () => (
  <svg width="22px" height="16px" viewBox="0 0 22 16" version="1.1">
    <title>menu</title>
    <desc>Created with Sketch.</desc>
    <g
      id="Landing-page"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="HT-Mobile-Landing-page"
        transform="translate(-338.000000, -39.000000)"
        stroke="#000000"
        strokeWidth="2"
      >
        <g id="menu" transform="translate(339.000000, 39.000000)">
          <path d="M0,7.99999957 L20,7.99999957" id="Path" />
          <path d="M0,1.14285757 L20,1.14285757" id="Path" />
          <path d="M0,14.8571426 L20,14.8571426" id="Path" />
        </g>
      </g>
    </g>
  </svg>
);

export default HamburgerIcon;
