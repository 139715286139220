const MAKINGMOVES_MAIL = "info@makingmoves.nl";
const FAVICON =
  "https://devhiringtoolstorage.blob.core.windows.net/icon/favicon.ico";

const validateEmail = (mail: string): boolean => {
  const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return regex.test(mail);
};

const validatePhone = (phoneNumber: string): boolean => {
  const regex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;

  return regex.test(phoneNumber);
};

export { MAKINGMOVES_MAIL, FAVICON, validateEmail, validatePhone };
