import React, { Component } from "react";
import { Link } from "gatsby";

import LocalizedLink from "../LocalizedLink";

import { LangMenuIcon } from "../../assets/icons";

import {
  homePath,
  sciencePath,
  pricingPath,
  contactUsPath,
  aboutUsPath,
  bookDemoPath,
} from "../../utils/paths";

import { IPrismicHeader } from "../../scenes/prismic.model";

import * as styles from "./Header.module.scss";

interface IProps {
  data: IPrismicHeader;
  pageContext: {
    locale: string;
    name: string;
  };
}

interface IState {
  isLanguageMenuOpen: boolean;
}

class Navbar extends Component<IProps, IState> {
  state = {
    isLanguageMenuOpen: false,
  };

  render() {
    const { data, pageContext } = this.props;
    const { isLanguageMenuOpen } = this.state;

    return (
      <div className={styles.navbarContainer}>
        <LocalizedLink
          to={sciencePath}
          isActive={pageContext.name === sciencePath.substring(1)}
        >
          {data.how_it_works_link.text}
        </LocalizedLink>
        <LocalizedLink
          to={pricingPath}
          isActive={pageContext.name === pricingPath.substring(1)}
        >
          {data.pricing_link.text}
        </LocalizedLink>
        <LocalizedLink
          to={aboutUsPath}
          isActive={pageContext.name === aboutUsPath.substring(1)}
        >
          {data.about_us_link.text}
        </LocalizedLink>
        <LocalizedLink
          to={contactUsPath}
          isActive={pageContext.name === contactUsPath.substring(1)}
        >
          {data.contact_us_link.text}
        </LocalizedLink>
        <div className={styles.languageButtonContainer}>
          <button
            type="button"
            className={styles.languageButton}
            onClick={this.toggleLanguageMenu}
          >
            {pageContext.locale === "en-us" ? "EN" : "NL"}
            <LangMenuIcon />
          </button>
          {isLanguageMenuOpen && (
            <ul className={styles.languageDropMenuContainer}>
              <li>
                <div>
                  <Link hrefLang="en-us" to={homePath}>
                    <p>ENGLISH</p>
                  </Link>
                </div>
              </li>
              <li>
                <div>
                  <Link hrefLang="nl-nl" to="/nl">
                    <p>DUTCH</p>
                  </Link>
                </div>
              </li>
            </ul>
          )}
        </div>
        <a
          className={styles.loginLink}
          href={`${process.env.HT_APP_URL}/login?locale=${this.props.pageContext.locale}`}
        >
          {data.login_link.text}
        </a>
        <a
          className={styles.signupLink}
          href={`${process.env.HT_APP_URL}/register?locale=${this.props.pageContext.locale}`}
        >
          {data.signup_link.text}
        </a>
        <LocalizedLink className={styles.bookDemoLink} to={bookDemoPath}>
          {data.book_demo_link.text}
        </LocalizedLink>
      </div>
    );
  }

  private toggleLanguageMenu = () => {
    this.setState({
      isLanguageMenuOpen: !this.state.isLanguageMenuOpen,
    });
  };
}

export default Navbar;
