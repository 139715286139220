import React from "react";

const PlusIcon = () => (
  <svg width="17px" height="17px" viewBox="0 0 17 17" version="1.1">
    <title>Plus</title>
    <desc>Created with Sketch.</desc>
    <g id="Mobile" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="HT-IQ-product-page-mobile-Copy"
        transform="translate(-331.000000, -1600.000000)"
        fill="#444444"
        fillRule="nonzero"
      >
        <path
          d="M346.67623,1607.21107 C347.047816,1607.21107 347.361338,1607.33299 347.616803,1607.57684 C347.872269,1607.8207 348,1608.12841 348,1608.5 C348,1608.87159 347.872269,1609.1793 347.616803,1609.42316 C347.361338,1609.66701 347.047816,1609.78893 346.67623,1609.78893 L340.788934,1609.78893 L340.788934,1615.67623 C340.788934,1616.04782 340.667009,1616.36134 340.423156,1616.6168 C340.179302,1616.87227 339.871587,1617 339.5,1617 C339.128413,1617 338.820698,1616.87227 338.576844,1616.6168 C338.332991,1616.36134 338.211066,1616.04782 338.211066,1615.67623 L338.211066,1609.78893 L332.32377,1609.78893 C331.952184,1609.78893 331.638662,1609.6612 331.383197,1609.40574 C331.127731,1609.15027 331,1608.84836 331,1608.5 C331,1608.12841 331.127731,1607.8207 331.383197,1607.57684 C331.638662,1607.33299 331.952184,1607.21107 332.32377,1607.21107 L338.211066,1607.21107 L338.211066,1601.32377 C338.211066,1600.95218 338.332991,1600.63866 338.576844,1600.3832 C338.820698,1600.12773 339.128413,1600 339.5,1600 C339.871587,1600 340.179302,1600.12773 340.423156,1600.3832 C340.667009,1600.63866 340.788934,1600.95218 340.788934,1601.32377 L340.788934,1607.21107 L346.67623,1607.21107 Z"
          id="+-copy-6"
        />
      </g>
    </g>
  </svg>
);

export default PlusIcon;
