import React from "react";
import { Link } from "gatsby";
import { LocaleContext } from "../Layout/Layout";

import * as styles from "./LocalizedLink.module.scss";

const locales = require("../../../config/i18n");

interface IProps {
  to: string;
  className?: string;
  isActive?: boolean;
  children: any;
}

export default (props: IProps) => {
  const context = React.useContext(LocaleContext)!;
  const isIndex = props.to === "/";

  let path = props.to;
  if (context && context.locale) {
    path = locales[context.locale].default
      ? props.to
      : `/${locales[context.locale].path}${isIndex ? "" : `${props.to}`}`;
  }

  const isActive = props.isActive ? styles.active : null;
  const classes = props.className ? props.className : null;
  let className = "";

  if (isActive) {
    className += isActive;
  }
  if (classes) {
    className += classes;
  }

  return (
    <Link className={className} to={path}>
      {props.children}
    </Link>
  );
};
