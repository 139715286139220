import React from "react";

const ConfirmationIcon = () => (
  <svg width="10px" height="7px" viewBox="0 0 10 7" version="1.1">
    <title>Confirmation</title>
    <desc>Created with Sketch.</desc>
    <g
      id="Benjamin"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="invite---success-1/2"
        transform="translate(-574.000000, -543.000000)"
        stroke="#81BE62"
        strokeWidth="2"
      >
        <g id="emails" transform="translate(574.000000, 511.000000)">
          <g id="Group-9" transform="translate(1.000000, 27.000000)">
            <polyline id="Path" points="7.75 6 2.59375 11 0.25 8.72727273" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ConfirmationIcon;
