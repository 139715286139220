const homePath = "/";
const sciencePath = "/science";
const pricingPath = "/pricing";
const aboutUsPath = "/about";
const contactUsPath = "/contact";
const bookDemoPath = "/book-demo";

export {
  homePath,
  sciencePath,
  pricingPath,
  contactUsPath,
  aboutUsPath,
  bookDemoPath,
};
